import { handleActions } from 'redux-actions';
import Types from './types';
import { ConfigState } from './interfaces';

const initialState: ConfigState = {
  primary: {
    contrastText: '#5b52c4',
    dark: '#000A62',
    main: '#000A62',
    light: '#000A62',
  },
  secondary: {
    contrastText: '#357FFA',
    dark: '#357FFA',
    main: '#357FFA',
    light: '#357FFA',
  },
  primaryColor: '#000A62',
  secondaryColor: '#357FFA',
  backgroundColor: '#0064af',
  textColorHover: '#FFF',
  imageLoginBackgroundColor: '#F7B528',
  logoBackgroundColor: '#fafafa',
  imageUrl: '/images/background.png',
  logoUrl: '/images/SkyoneAutoskyLogoHR.png',
  headerLoginFontSize: '45px',
  descriptionLoginFontSize: '16px',
  maintenanceImage: '/images/newMaintenance.png',
  messagesImage: '/images/newYellow.png',
  messageTextColor: '#E1AF44',
  title: 'Skyone Autosky',
  favicon: '/favicon.png',
  activeDirectoryType: 'interno',
  defaultBannerOption: 0,
  reload: true,
  showNews: false,
};

export default handleActions(
  {
    [Types.CONFIG_REQUEST]: (state: ConfigState) => ({
      ...state,
      reload: true,
    }),
    [Types.CONFIG_FULFILLED]: (state: ConfigState, { payload }) => ({
      ...state,
      ...payload,
      reload: false,
    }),
    [Types.CONFIG_CHANGE_SHOW_NEWS]: (state: ConfigState, { payload }) => ({
      ...state,
      showNews: payload,
    }),
  },
  initialState
);
